import React from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import { ApolloClient, HttpLink, ApolloLink, ApolloProvider, InMemoryCache, concat } from '@apollo/client';

import Themes from './themes';
import App from './App';
import { SttTranslateHook } from '@stt-componentes/core';
import * as sw from './sw';
import { Cache } from '@stt-componentes/cache';
import DeviceStatus from './components/DeviceStatus';

const initializeApp = (config) => {
    global.gConfig = config;

    /**
     * Configuração Graphql
     */
    const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
            headers: {
                authorization: `Bearer ${Cache.getAccessToken()}`,
            },
        });

        return forward(operation);
    });
    const httpLink = new HttpLink({ uri: `${config.url_base_graphql}/graphql`, useGETForQueries: true });
    const client = new ApolloClient({
        link: concat(authMiddleware, httpLink),
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });

    const container = document.getElementById('root');
    const root = createRoot(container);
    root.render(
        // <React.StrictMode>
        <ApolloProvider client={client}>
            <ThemeProvider theme={Themes.default}>
                <CssBaseline />
                <SttTranslateHook.I18nContextProvider modulo={'analise'}>
                    <DeviceStatus />
                    <App />
                </SttTranslateHook.I18nContextProvider>
            </ThemeProvider>
        </ApolloProvider>
        // </React.StrictMode>
    );
};

const verificarBaseAlternativa = (config) => {
    let subpaths = window.location.pathname;
    if (subpaths && config.layoutsAlternativos) {
        subpaths = subpaths.split('/');

        let layoutAlternativo = null;
        subpaths.forEach((sp) => {
            if (config.layoutsAlternativos.includes(sp)) {
                layoutAlternativo = sp;
                return true;
            }
        });

        if (layoutAlternativo) {
            return fetch(`${process.env.PUBLIC_URL}/config/layoutsAlternativos/${layoutAlternativo}/config.stt-${config.config_id}.json`, {
                headers: { 'Content-Type': 'application/json' },
            }).then((response) => response.json());
        }
    }
    return config;
};

Promise.all([
    fetch(`${process.env.PUBLIC_URL}/config/changelog.json`).then((response) => response.json()),
    fetch(`${process.env.PUBLIC_URL}/config/config.json`).then((response) => response.json()),
])
    .then(([changelog, config]) => Promise.all([changelog, verificarBaseAlternativa(config)]))
    .then(([changelog, config]) => initializeApp({ ...config, ...changelog }))
    .catch((error) => {
        console.error('Erro ao buscar os arquivos de configurações do frontend', error);
    });

sw.unregister();
