import MSGCode from "./MSGCode";
import { GraphQLError } from 'graphql';
const GET_GRAPHQL_ERRORS = {
  [MSGCode.ERR003]: {
    code: 'SAVE_ERROR',
    message: 'Não foi possivel salvar. Relatório bloqueado.'
  },
  [MSGCode.ERR004]: {
    code: 'AUTH_ERROR',
    message: 'Problema na autorização do token.'
  },
  default: {
    code: 'GENERIC_ERROR',
    message: 'Erro genérico.'
  }
};
/**
 * type: alert | confirmation | success | error | info (only notification)
 * typeComponent: prompt | notification
 */
const GET_ALERTS = strings => ({
  [MSGCode.ERR001]: {
    title: strings.Comuns.erro,
    message: strings.Mensagens.err001,
    type: 'error',
    typeComponent: 'prompt'
  },
  [MSGCode.ERR002]: {
    title: strings.Comuns.erro,
    message: strings.Mensagens.err002,
    type: 'error',
    typeComponent: 'prompt'
  },
  [MSGCode.ERR003]: {
    title: strings.Comuns.alerta,
    message: 'Não foi possivel salvar. Relatório bloqueado.',
    type: 'alert',
    typeComponent: 'prompt'
  },
  [MSGCode.ERR005]: {
    title: strings.Comuns.alerta,
    message: 'A pesquisa não foi salva. Por favor, salve-a antes de continuar.',
    type: 'alert',
    typeComponent: 'notification'
  },
  [MSGCode.ALE001]: {
    title: strings.Comuns.alerta,
    message: strings.Mensagens.ale001,
    type: 'alert',
    typeComponent: 'prompt'
  },
  [MSGCode.ALE002]: {
    title: strings.Comuns.alerta,
    message: 'Para esse agendamento é necessário informar o intervalo do filtro.',
    type: 'alert',
    typeComponent: 'prompt'
  },
  [MSGCode.SUC001]: {
    title: strings.Comuns.sucesso,
    message: strings.Mensagens.suc001,
    type: 'success',
    typeComponent: 'notification'
  },
  [MSGCode.SUC002]: {
    title: strings.Comuns.sucesso,
    message: strings.Agendamento.atualizadoComSucesso,
    type: 'success',
    typeComponent: 'notification'
  },
  [MSGCode.CON001]: {
    title: strings.Mensagens.tituloCon001,
    message: strings.Mensagens.mensagemCon001,
    type: 'confirmation',
    typeComponent: 'prompt'
  },
  [MSGCode.CON002]: num => ({
    title: strings.Mensagens.tituloCon002,
    message: strings.Mensagens.mensagemCon002.replaceAll('{num}', num),
    type: 'confirmation',
    typeComponent: 'prompt'
  }),
  [MSGCode.CON003]: {
    title: strings.Mensagens.tituloCon003,
    message: strings.Mensagens.mensagemCon003,
    type: 'confirmation',
    typeComponent: 'prompt'
  },
  [MSGCode.CON004]: {
    title: strings.Mensagens.tituloCon004,
    message: strings.Mensagens.mensagemCon004,
    type: 'confirmation',
    typeComponent: 'prompt'
  }
});
class MSGBuilder {
  constructor(msgCode, stringsI18N) {
    this.msgCode = msgCode;
    this.stringsI18N = stringsI18N;
  }
  /**
   * Construção do erro graphql no backend.
   *
   * @param msgCode
   * @returns
   */
  buildError(msg) {
    const graphqlError = GET_GRAPHQL_ERRORS[this.msgCode] || GET_GRAPHQL_ERRORS["default"];
    return new GraphQLError(msg || graphqlError.message, {
      extensions: {
        code: graphqlError.code,
        msgCode: this.msgCode
      }
    });
  }
  /**
   * Construção da mensagem no frontend.
   *
   * @param msgCode
   * @returns
   */
  buildMsgAlert() {
    const mapAlerts = GET_ALERTS(this.stringsI18N);
    return mapAlerts[this.msgCode];
  }
}
export default MSGBuilder;